/*--
	Author: W3Layouts
	Author URL: http://w3layouts.com
	License: Creative Commons Attribution 3.0 Unported
	License URL: http://creativecommons.org/licenses/by/3.0/
--*/


html,
body {
    margin: 0;
    font-size: 100%;
    background: #fff;
    scroll-behavior: smooth;
}

a:hover {
    text-decoration: none;
}

select {
  color: #525252;
  font-size: 18px;
  width: 100%;
  padding: 10px 0 10px 10px;
  border: 0 !important;
  /* needed */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* SVG background image */
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%238C98F2'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat;
  background-size: 12px;
  background-position: calc(100% - 20px) center;
  background-repeat: no-repeat;
  background-color: #efefef;
}

input[type="button"],
input[type="submit"],
input[type="text"],
input[type="email"],
input[type="tel"],
input[type="search"] {
    transition: 0.5s all;
    -webkit-transition: 0.5s all;
    -moz-transition: 0.5s all;
    -o-transition: 0.5s all;
    -ms-transition: 0.5s all;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    letter-spacing: 1px;
    font-weight: 700;
}

p {
    font-size: 0.9em;
    color: white;
    line-height: 2em;
    letter-spacing: 0.075em;
}

ul {
    margin: 0;
    padding: 0;
}


/*--/header --*/

/* top bar */

.header-top_w3layouts {
    padding: 0 .5em;
}

.top-bar-w3layouts {
    border-bottom: 1px solid rgba(221, 221, 221, 0.18);
}

/* social icons */

.top-right-info li span {
    color: #fff;
    width: 30px;
    height: 30px;
    line-height: 2.2;
    font-size: 14px;
    text-align: center;
    border-radius: 50%;
    transition: 0.5s all;
    -webkit-transition: 0.5s all;
    -moz-transition: 0.5s all;
    -o-transition: 0.5s all;
    -ms-transition: 0.5s all;
}

.top-right-info li span.fa-facebook-f {
    background: #3b5998;
}

.top-right-info li span.fa-twitter {
    background: #1da1f2;
}

.top-right-info li span.fa-google-plus {
    background: #F44336;
}

.top-right-info li span.fa-dribbble {
    background: #ea4c89;
}

.top-right-info li span:hover,
.top-w3layouts p a:hover {
    opacity: 0.8;
    transition: 0.5s all;
    -webkit-transition: 0.5s all;
    -moz-transition: 0.5s all;
    -o-transition: 0.5s all;
    -ms-transition: 0.5s all;
}

.top-right-info li {
    display: inline-block;
}

/* //social icons */

.header-top_w3layouts p,
.header-w3layouts p,
.top-w3layouts p {
    font-size: 14px;
    display: inline-block;
    color: black;
    letter-spacing: 1px;
}

.top-w3layouts p a:hover,
.w3l-footer ul li a:hover {
    opacity: .7;
}

a.login-button-2 {
    letter-spacing: 1px;
    font-size: 12px;
    background: #341f97;
    padding: 11px 20px;
    border-radius: 40px;
}

.top-right-info li p {
    color: black;
}

/* //top bar */

/*-- header --*/

/*-- header --*/

/* CSS Document */

.toggle,
[id^=drop] {
    display: none;
}

/* Giving a background-color to the nav container. */

nav {
    margin: 0;
    padding: 0;
}

/* Navbar queries */
@media (max-width: 768px) {
}


#logo a {
    float: left;
    font-size: .8em;
    text-transform: capitalize;
    display: initial;
    margin: 0;
    letter-spacing: 1px;
    color: #fff;
    font-weight: 300;
}

#logo a span {
    color: #30c39e;
}

#logo2 {
    position: relative;
    bottom: 30px;
}

/* Since we'll have the "ul li" "float:left"
 * we need to add a clear after the container. */

nav:after {
    content: "";
    display: table;
    clear: both;
}

/* Removing padding, margin and "list-style" from the "ul",
 * and adding "position:reltive" */

nav ul {
    float: right;
    padding: 0;
    margin: 0;
    list-style: none;
    position: relative;
}

/* Positioning the navigation items inline */

nav ul li {
    margin: 0px;
    float: left;
}

/* Styling the links */

nav a {
    color: black;
    font-weight: 300;
    font-size: 15px;
    letter-spacing: 1px;
    padding-left: 0;
    padding-right: 0;
    padding: 10px 0;
}


nav ul li ul li:hover {
    background: #f8f9fa;
}

/* Background color change on Hover */

nav a:hover {
    color: rgb(70, 108, 179);
}

.active {
    color: rgb(70, 108, 179);
}
.active-mobile {
    color: rgb(154, 247, 144) !important;
}

/* Hide Dropdowns by Default
 * and giving it a position of absolute */

nav ul ul {
    display: none;
    position: absolute;
    /* has to be the same number as the "line-height" of "nav a" */
    top: 30px;
    background: #fff;
    padding: 10px;
}

/* Display Dropdowns on Hover */

nav ul li:hover > ul {
    display: inherit;
}

/* Fisrt Tier Dropdown */

nav ul ul li {
    width: 170px;
    float: none;
    display: list-item;
    position: relative;
}

nav ul ul li a {
    color: black;
    padding: 5px 10px;
    display: block;
}

/* Second, Third and more Tiers	
 * We move the 2nd and 3rd etc tier dropdowns to the left
 * by the amount of the width of the first tier.
*/

nav ul ul ul li {
    position: relative;
    top: -60px;
    /* has to be the same number as the "width" of "nav ul ul li" */
    left: 170px;
}


/* Change ' +' in order to change the Dropdown symbol */

li > a:only-child:after {
    content: '';
}

/* About 
--------------------------------------------- */
.about {
    background: url(../images/city.jpg);
    background-size: cover;
    background-attachment: fixed;
}

/*------------------------------------------- */


/* Media Queries
--------------------------------------------- */

@media all and (max-width: 768px) {

    #logo {
        display: block;
        padding: 0;
        width: 100%;
        text-align: center;
        float: none;
    }

    nav {
        margin: 0;
    }

    /* Hide the navigation menu by default */
    /* Also hide the  */
    .toggle + a,
    .menu {
        display: none;
    }

    /* Stylinf the toggle lable */
    .toggle {
        display: block;
        padding: 8px 20px;
        font-size: 15px;
        text-decoration: none;
        border: none;
        float: right;
        background-color: #ffffff;
        color: #fff;
        cursor: pointer;
    }
    .menu .toggle {
        float: none;
        text-align: center;
        margin: auto;
        width: 80%;
        padding: 5px;
        font-weight: normal;
        font-size: 16px;
        letter-spacing: 1px;
    }

    .menu-icon {
        font-size: 25px !important;
    }

    .toggle:hover {
        color: #333;
        background-color: #fff;
    }

    /* Display Dropdown when clicked on Parent Lable */
    
    /*
    [id^=drop]:checked + ul {
        display: block;
        background: rgba(16, 16, 16, 0.85);
        padding: 15px 0;
        text-align: center;
    }*/
    

    /* Change menu item's width to 100% */
    nav ul li {
        display: block;
        width: 100%;
        padding: 5px 0;
    }

    nav ul ul .toggle,
    nav ul ul a {
        padding: 0 40px;
    }

    nav ul ul ul a {
        padding: 0 80px;
    }

    nav a:hover,
    nav ul ul ul a {
        background-color: transparent;
    }

    nav ul li ul li .toggle,
    nav ul ul a,
    nav ul ul ul a {
        padding: 14px 20px;
        color: #FFF;
        font-size: 17px;
    }


    nav ul li ul li .toggle,
    nav ul ul a {
        background-color: #212121;
    }

    /* Hide Dropdowns by Default */
    nav ul ul {
        float: none;
        position: static;
        color: #ffffff;
        /* has to be the same number as the "line-height" of "nav a" */
    }

    /* Hide menus on hover */
    nav ul ul li:hover > ul,
    nav ul li:hover > ul {
        display: none;
    }

    /* Fisrt Tier Dropdown */
    nav ul ul li {
        display: block;
        width: 100%;
        padding: 0;
    }

    nav ul ul ul li {
        position: static;
        /* has to be the same number as the "width" of "nav ul ul li" */
    }
    nav ul ul li a {
        color: #fff;
        font-size: 0.85em;
    }
}

@media all and (max-width: 330px) {

    nav ul li {
        display: block;
        width: 94%;
    }

}

/* header */

/*-- banner --*/

.main-content {
    background-color: white;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    -ms-background-size: cover;
    height: auto;
    position: relative;
}

.layer {
    background: white;
    height: auto;
}

.main-content.inner {
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    -ms-background-size: cover;
    min-height: 48vw;
    position: relative;
}

.banner-info-wthree p {
    color: #fff;
    letter-spacing: 1px;
}

.banner-info-wthree h3 {
    font-size: 1.6em;
    letter-spacing: 1px;
    color: rgba(0, 42, 123, 0.71);
    margin: 0.7em 0;
    text-transform: uppercase;
}

.banner-info-wthree h2 {
    color: #4f8c2b;
    font-size: 0.9em;
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: 9px;
}


.banner-info-wthree a:hover {
    background: #00cec9;
    color: #fff;
    border: transparent;
}

/* image layers effect */

.banner-info-wthree {
    margin: 0 auto;
    text-align: center;
    padding-top: 4em;
    position: relative;
}

.img-effect img {
    padding: 10px;
    background: #f6f6f9;
}

/*--/ mouse --*/

.scroll:hover {
    background: transparent !important;
}

.scroll {
    height:100px;
    display: block;
}

.icon-scroll {
    margin: 0 auto;
    width: 1em;
    height: 3.125em;
    transform: translateX(-50%) scale(2);
    z-index: 1;
}

.icon-arrows::after,
.icon-arrows::before {
    content: '';
}

.icon-arrows span,
.icon-arrows::after,
.icon-arrows::before {
    display: block;
    width: 0.315em;
    height: 0.315em;
    border-right: 1px solid rgba(0, 42, 123, 0.71);
    border-bottom: 1px solid rgba(0, 42, 123, 0.71);
    margin: 0 0 0.125em 0.315em;
    transform: rotate(45deg);
    animation: mouse-scroll 1s infinite;
    animation-direction: alternate;
}

.icon-arrows::before {
    margin-top: 0.315em;
    animation-delay: .1s;
}

.icon-scroll span {
    animation-delay: .2s;
}

.icon-arrows::after {
    animation-delay: .3s;
}

.icon-scroll .mouse {
    height: 1.375em;
    width: .875em;
    border: 1px solid rgba(0, 42, 123, 0.71);
    border-radius: 2em;
}

.icon-scroll .wheel {
    position: relative;
    display: block;
    height: 0.1875em;
    width: 0.1875em;
    margin: 0.1875em auto 0;
    background: rgba(0, 42, 123, 0.71);
    animation: mouse-wheel 1.2s ease infinite;
    border-radius: 50%;
}

@keyframes mouse-wheel {
    0% {
        opacity: 1;
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        transform: translateY(.375em);
    }
}

@keyframes mouse-scroll {
    0% {
        opacity: 0;
    }
    50% {
        opacity: .5;
    }
    100% {
        opacity: 1;
    }
}

/*--// mouse --*/

/*-- popup --*/

.pop-overlay {
    position: fixed;
    top: 0px;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 0ms;
    visibility: hidden;
    opacity: 0;
    z-index: 99;
}

.pop-overlay:target {
    visibility: visible;
    opacity: 1;
}

.popup {
    background: #fff;
    border-radius: 5px;
    width: 35%;
    position: relative;
    margin: 8em auto;
    padding: 3em 1em;
}

.popup p {
    font-size: 15px;
    color: #666;
    letter-spacing: .5px;
    line-height: 30px;
}

.popup h2 {
    margin-top: 0;
    color: #fff;

}

.popup .close {
    position: absolute;
    top: 5px;
    right: 15px;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: #000;
}

.popup .close:hover {
    color: #30c39e;
}


/*-- //popup --*/

/* //image layers effect */

/*-- //banner --*/

/*-- features --*/

h3.title,
h3.title.two {
    font-size: 2em;
    letter-spacing: 1px;
    color: white;
    font-weight: 700;
    text-transform: uppercase;
}

h3.title.two {
    color: #fff;
}

.sub-title {
    font-size: 0.35em;
    color: white;
    font-weight: 500;
    letter-spacing: 1px;
    display: block;
    text-transform: uppercase;
}

.sub-title.two {
    color: #fff;
}

.feature-grids h3 {
    font-size: 1.2em;
    font-weight: 500;
    letter-spacing: 2px;
    color: white;
}

.feature-grids p {
    margin: 0;
    color: white;
    line-height: 28px;
}

.feature-grids span {
    font-size: 1.5em;
    vertical-align: middle;
    width: 60px;
    height: 60px;
    line-height: 2.5em;
    margin: 0.5em 0;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -o-border-radius: 50%;
    -ms-border-radius: 50%;
    -moz-border-radius: 50%;
    background: white;
    color: rgba(0, 42, 123, 0.71);
}

.see-all {
    position:relative; 
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 10px;
    background-color:#7eb97e !important;
}

.bottom-gd:hover,
.bottom-gd2-active {
    transition: .5s all;
    -webkit-transition: .5s all;
    -moz-transition: .5s all;
    -ms-transition: .5s all;
    -o-transition: .5s all;
}

.bottom-gd:hover span,
.bottom-gd2-active span {
    background: #78b753;
    transition: .5s all;
    -webkit-transition: .5s all;
    -moz-transition: .5s all;
    -ms-transition: .5s all;
    -o-transition: .5s all;
    color: white;
}

.feature-grids span {
    text-align: center;
}

/*-- //features --*/

/*--/counter--*/

.stats {
    background: #282b34;
    padding: 4em 0;
}

.counter {
    background: none;
    padding: 20px 0;
    border-radius: 0;
    color: #fff;
}

.count-title {
    font-size: 3em;
    margin-top: 10px;
    margin-bottom: 0;
    text-align: center;
    font-weight: 600;
    color: #96d472;
}

.count-text {
    font-size: 13px;
    margin-top: 10px;
    margin-bottom: 0;
    text-align: center;
    color: white;
    font-weight: 500;
}

.fa-2x {
    margin: 0 auto;
    float: none;
    display: table;
    color: #4ad1e5;
}

/*--//counter--*/

/*--/team--*/


.team-info h3 {
    color: white;
    margin-bottom: 0.8em;
    font-size: 1.2em;
    font-weight: 400;
    letter-spacing: 3px;
    position: relative;
}

.sub-title-team {
    font-size: 0.67em;
    color: #4392fb;
    font-weight: 600;
    letter-spacing: 2px;
    display: block;
    text-transform: uppercase;
}

ul.team-icons li {
    list-style: none;
    display: inline-block;
    margin-right: 1em;
}

ul.team-icons li a span {
    color: #333;
    text-decoration: none;
    font-size: 18px;
}

ul.team-icons li a {
    color: #333;
    font-size: 18px;
}

.rsvp img {
    background: #eaebec;
    padding: 0.7em;
}

ul.team-icons.new-inf li a,
ul.team-icons.new-inf li span {
    font-size: 15px;
    color: #666;
}

ul.team-icons.new-inf li {
    color: #666;
    font-size: 15px;
}

.team-gd {
    transition: 2s all;
    -webkit-transition: 2s all;
    -moz-transition: 2s all;
    -ms-transition: 2s all;
    -o-transition: 2s all;

    border: transparent;
}


.team-gd img {
    box-shadow: 0 20px 40px -10px rgba(0, 0, 0, .3);
}



@media (min-width: 768px) {
    .team-gd-small p {
        font-size: .7em;
    }
}


/*--//team--*/

/*-- news --*/

.news-grids h4 {
    font-size: 1.35em;
    font-weight: 400;
    letter-spacing: 3px;
    color: #fb4386;
    position: relative;
}

.news-grids p {
    font-size: 15px;
    line-height: 1.8em;
    color: #777;
}

.learn-link-bnr1 {
    padding: 12px 40px;
    color: #ffffff;
    font-weight: 700;
    letter-spacing: 0.25em;
    font-size: 14px;
    background: #fb4386;
    border: transparent;
    text-transform: uppercase;
}

.news-grids img {
    border-radius: 4px;
}

section.news {
    background: #f3f3f3;
    position: relative;
}

section.news img {
    padding: 10px;
    background: #fff;
}


/*-- //news --*/



/*-- /gallery --*/

section#gallery {
    background: #00cec9;
    position: relative;
}

.gal-img img {
    padding: 10px;
    background: #fff;
}

/*-- //gallery --*/



/* contact */

section.contact {
    background: #282b34;
    position: relative;
}

p.main_p4 {
    color: #b1b4b9;
    width: 80%;
    font-size: 1em;
    letter-spacing: 0.075em;
    line-height: 1.9em;
}



.contact_grid_right textarea {
    min-height: 13em;
    margin: 1em 0em;
    resize: none;
}

.contact_grid_right input[type="submit"],
.contact_grid_right input[type="reset"] {
    outline: none;
    padding: 14px 0;
    font-size: 15px;
    color: #fff;
    background: none;
    border: 1px solid rgba(255, 255, 255, 0.28);
    width: 30%;
    letter-spacing: 0.25em;
    border-radius: 0.25em;
    -webkit-transition: 0.5s all;
    -moz-transition: 0.5s all;
    -o-transition: 0.5s all;
    -ms-transition: 0.5s all;
    transition: 0.5s all;
    font-weight: 700;
    cursor: pointer;
    text-transform: uppercase;
}

.contact_grid_right input[type="submit"],
.contact_grid_right input[type="reset"]:hover {
    background-color: #00cec9;
    border: 1px solid #00cec9;

}

ul.social_section_1info li {
    display: inline-block;
}

ul.social_section_1info li a {
    width: 50px;
    height: 50px;
    text-align: center;
    display: block;
    line-height: 50px;
    border-radius: 50%;
    border: 1px solid #d6d6d6;
}

ul.social_section_1info a {
    color: #999;
    margin-right: 30px;
    font-size: 22px;
}

ul.social_section_1info a:hover {
    color: #000;
}

.cpy-right-w3layouts-pvt {
    background: rgb(32, 61, 107);
}

.cpy-right-w3layouts-pvt p {
    letter-spacing: 1px;
}

.cpy-right-w3layouts-pvt p a {
    color: #fb4386;
}

.cpy-right-w3layouts-pvt p a:hover {
    color: #999;
}

.cpy-right-w3layouts-pvt.text-center.py-5 {
    border-top: 1px solid rgba(230, 230, 230, 0.15);
}

#success-message {
    opacity: 0;
}

.contact-top {
    border-top: 1px solid #3e3e3e;
    padding: 4em 0;
    margin: 4em 0;
}

.col-xs-12.col-sm-12.col-md-12.col-lg-12 {
    padding: 0 20% 0 20%;
}

.form-title {
    padding: 25px;
    font-size: 30px;
    font-weight: 300;
}

.form-group .form-control {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-bottom: 1px;
    border-style: none none solid none;
    border-color: #ffd708;
}

.form-group .form-control:focus {
    box-shadow: none;
    border-width: 0 0 2px 0;
    border-color: #000;
}

textarea {
    resize: none;
}

.btn-mod.btn-large {
    height: auto;
    padding: 13px 52px;
    font-size: 15px;
}

.btn-mod.btn-border {
    color: #000000;
    border: 1px solid #000000;
    background: transparent;
}

.btn-mod,
a.btn-mod {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 4px 13px;
    color: #fff;
    background: rgba(34, 34, 34, .9);
    border: 1px solid transparent;
    font-size: 11px;
    font-weight: 400;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 2px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    -webkit-transition: all 0.2s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -moz-transition: all 0.2s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -o-transition: all 0.2s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.2s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    transition: all 0.2s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}

.btn-mod.btn-border:hover,
.btn-mod.btn-border:active,
.btn-mod.btn-border:focus,
.btn-mod.btn-border:active:focus {
    color: #fff;
    border-color: #000;
    background: #000;
    outline: none;
}

@media only screen and (max-width: 500px) {
    .btn-mod.btn-large {
        padding: 6px 16px;
        font-size: 11px;
    }
    .form-title {
        font-size: 20px;
    }
}

form#contact-form label {
    color: #ea4335;
}

label {
    color: #000 !important;
    font-weight: 600;
    letter-spacing: 0.5px;
}

/* /testimonials */

.team-gd.test-grid {
    padding: 4em;
    background: transparent;
}

.team-gd.test-grid:hover {
    background: #f0f3f3;
}

.team-gd.test-grid img {
    width: 30%;
}

/* //testimonials */

/* //contact */

.list-unstyled {
    padding-left: 0;
    list-style: none;
}

.contact_grid_left ul li i {
    float: right;
    font-size: 16px;
    color: #fff;
}

.contact_grid_left ul li p span {
    display: block;
}

.contact_grid_left ul li p,
.contact_grid_left ul li a {
    color: #bfbfbf;
    letter-spacing: 1px;
}

ul.list-unstyled li {
    width: 100%;
}

.contact_grid_left h6 {
    font-size: 1em;
    color: rgb(113, 162, 228);
    font-weight: 500;
    text-transform: uppercase;
}

.contact_grid_left li i {
    background: rgba(255, 255, 255, 0.17);
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
}

/* contact */

a.move-top span {
    color: #d7e1ea;
    width: 34px;
    height: 34px;
    border: 1px solid #d7e1ea;
    line-height: 1.9em;
}

.form-control {
    background-color: #e2e2e2;
    border: none;
}

.map iframe {
    width: 100%;
    border: 7px solid #e4e4e4;
    min-height: 500px;
}

.con-nav-item {
    margin-right: 10px;
}
/* //contact */


/*--responsive--*/

@media(max-width:1366px) {
    .icon-scroll {
        bottom: -43%;
        left: 50%;
    }
    .header-top_w3layouts p,
    .header-w3layouts p,
    .top-w3layouts p {
        font-size: 13px;
    }
}

@media(max-width:1280px) {}

@media(max-width:1080px) {

    .banner-info-wthree {
        padding-top: 4em;
    }
}

@media(max-width:1050px) {

    .featured-left {
        margin-top: 2em;
    }
}

@media(max-width:1024px) {
    .banner-info-wthree {
        padding-top: 4em;
    }
}

@media(max-width:991px) {

    .banner-info-wthree {
        padding-top: 4em;
    }
    .icon-scroll {
        bottom: -29%;
        left: 50%;
    }
    .gal-img {
        margin-bottom: 1em;
    }
    .news-grids h4 {
        font-size: 1.2em;
        letter-spacing: 2px;
    }
    .team-info h3 {
        margin-bottom: 0.8em;
        font-size: 1.1em;
        letter-spacing: 2px;
    }
    .team-gd.test-grid {
        padding: 2em 1em;
    }
    .contact_top {
        margin-top: 2em;
    }
    .gal-img {
        margin-bottom: 1em;
        float: left;
        width: 50%;
    }
    .newsgrid1 {
        float: left;
        width: 50%;
        margin-bottom: 1em;
    }
    .contact_grid_right {
        margin-top: 2em;
    }
    .contact_grid_right input[type="text"],
    .contact_grid_right input[type="email"],
    .contact_grid_right input[type="tel"] {
        margin-bottom: 1em;
    }
    .contact_grid_right textarea {
        margin-bottom: 0em;
    }
}

@media(max-width:800px) {
    .banner-info-wthree {
        padding-top: 4em;
    }
    .icon-scroll {
        bottom: -25%;
        left: 50%;
    }
}

@media(max-width:768px) {
    .banner-info-wthree h3 {
        font-size: 1.4em;
        letter-spacing: 1px;
        color: rgba(0, 42, 123, 0.71);
        margin: 0.3em 0;
        text-transform: uppercase;
    }
}

@media(max-width:736px) {
    .main-co ntent {
        min-height: 38em;
    }
    .banner-info-wthree h2 {
        letter-spacing: 6px;
    }
    .contact-top {
        padding: 3em 0;
        margin: 0em 0;
    }
    .feature-grids h3 {
        font-size: 1.1em;
        letter-spacing: 1px;
    }
    .popup {
        background: #fff;
        border-radius: 5px;
        width: 70%;
    }
}

@media(max-width:667px) {
    .banner-info-wthree h2 {
        letter-spacing: 4px;
    }
    .banner-info-wthree {
        padding: 4em 2em 0 2em;
    }
    .learn-link-bnr1 {
        padding: 10px 30px;
    }
    h3.title,
    h3.title.two {
        font-size: 1.8em;
    }
    .team-gd {
        padding: 1.5em 0em;
    }
    p.main_p4 {
        font-size: 0.85em;
        letter-spacing: 0.075em;
        width: 100%;
    }
    p.main_p4 {
        width: 100%;
    }
    .icon-scroll {
        bottom: -33%;
        left: 50%;
    }
}

@media(max-width:640px) {
    .banner-info-wthree h2 {
        font-size: 0.9em;
        letter-spacing: 3px;
    }
    h3.title,
    h3.title.two {
        font-size: 1.8em;
    }
}

@media(max-width:480px) {
    .banner-info-wthree {
        padding: 4em 1em 0 1em;
    }
    .banner-info-wthree h3 {
        font-size: 1.4em;
        margin: 0.3em 0;
    }
    h3.title,
    h3.title.two {
        font-size: 1.7em;
    }
    .main-co ntent {
        min-height: 36em;
    }
    .newsgrid1 {
        float: left;
        width: 100%;
        margin-bottom: 1em;
    }
    .popup {
        width: 90%;
    }
}

@media(max-width:414px) {
    h3.title,
    h3.title.two {
        font-size: 1.6em;
    }
    .banner-info-wthree {
        margin: 0 auto;
        padding-top: 4em;
    }
    .banner-info-wthree h3 {
        font-size: 1.3em;
        letter-spacing: 1px;
        margin: 0.3em 0;
    }

    .learn-link-bnr1 {
        padding: 10px 20px;
    }
    .news-grids h4 {
        font-size: 1em;
        letter-spacing: 1px;
    }
    .sub-title {
        font-size: 0.55em;
    }
    ul.list-unstyled li {
        width: 90%;
    }
}

@media(max-width:384px) {
    h3.title,
    h3.title.two {
        font-size: 1.5em;
    }
}

@media(max-width:375px) {
    h3.title,
    h3.title.two {
        font-size: 1.4em;
    }
}

/* Links
-------------------*/


section#links {
    background: #00cec9;
    position: relative;
}


.links, .links2 {
    color: white;
    font-size: 1.1em;
    letter-spacing: 1px;
}

.links a, .links2 a {
    color: #aed9ff;
}

.links .fa-external-link, .links2 .fa-external-link {
    margin-right: 4px;
}

.links-label {
    text-align:right;
    padding:0px 10px;
}

.links-select {
    margin-top:-10px;
    padding:0px 5px;
    width: 200px;
}
.links-select select {
    letter-spacing: .05em;
}

.links-btn {
    margin-top: -10px;
}

@media (max-width: 775px) {
    .links2 {
        display: grid;
    }
    .links-select {
        margin: 0 auto;
        margin-top: 10px;
    }
    .links-btn {
        margin-top: 5px;
    }

}


/* // Links --------------*/

.check {
    width: 12px;
    margin-right:3px;
    position: relative;
    bottom: 2px;
}
.service-label {
    font-weight:300;
    display: inline-block;
    color:white;
    margin-bottom: 4px;
}

.text-white {
    color: white;
}

.bg-white {
    background: white
}


/* ------- Homepage section  ------ */

#home {
    min-height: 40em;
}

#info-box-1 {
    color: white;
    padding: 25px;
    background: rgb(32, 61, 107);
}

#info-box-2 {
    color: white;
    padding: 25px;
    background: #52b352;
}

#info-box-1 p, #info-box-2 p {
    color: white;
}

/* Width of 90% + centers */
.width-90-c {
    width: 90%;
    margin: 0 auto;
}

#nav-logo, #nav-logo-2 {
    display: none;
}

@media (max-width: 850px) {
    #nav-logo-2 {
        float: left;
        position: relative;
        left: 30px;
        top: 10px;
        width: 130px;
        display: block;
    }
}

@media (max-width: 768px) {
    #nav-logo-2 {
        float: left;
        position: relative;
        left: 30px;
        top: 20px;
        width: 130px;
        display: block;
    }
}

@media (min-width: 851px) 
{
    #nav-logo {
        float: left;
        position: relative;
        left: 30px;
        top: 30px;
        width: 200px;
        display: block;
    }
}

.team-description {
    color: #616161;
}

.text-black {
    color: black !important;
}

.ft-pt8-em {
    font-size: .8em;
}


#main-panel-1 {
    min-height: 40em;
}

#dollar {
    color:white;
    display: inline;
    font-size: 1.5em;
}

#item-cost {
    margin-bottom:1em;
    width:150px
}

#payment-submit {
    width:400px
}

@media(max-width: 600px) {
    #payment-submit {
        width:300px;
        font-size: 10px;
    }
}

.btn-main {
    outline: none;
    padding: 14px 14px;
    font-size: 15px;
    color: rgb(32, 61, 107);
    background: none;
    width: auto;
    letter-spacing: 0.25em;
    border-radius: 0.25em;
    transition: 0.25s all;
    font-weight: 700;
    cursor: pointer;
    text-transform: uppercase;
    border: 2px solid rgb(32, 61, 107);
    display: table;
    margin: 0 auto;
}

.btn-main:hover {
    background-color: rgb(32, 61, 107);
    color:white;
}


@media(max-width: 900px) {
    #btn-team-2 {
        display: none;
    }
}
@media(min-width: 901px) {
    #btn-team-1 {
        display: none;
    }
}

button a {
    color: inherit;
}

.text-color-1 {
    color: #55af4c !important
}
.text-color-2 {
    color : rgba(0, 42, 123, 0.71) !important;
}

#test123 {
    background: url('../images/Front-min.JPG');
    background-size: cover;
    background-attachment: fixed;
}

@media (max-width: 767px) {
    .slant-sm {
        transform: rotate(2.5deg) skew(2.5deg);
    }
    .slant-sm h3, .slant-sm p {
        transform: rotate(-2.5deg) skew(-2.5deg);
    }
    
}

.bio {
    background: rgba(10, 37, 90, 0.93) !important
}


#inc {
    text-align: center;
    margin: 10px 10px;

}

.bg-main {
    background: rgb(32, 61, 107)
}

#footer a {
    color: white;
}

.text-sp-1 {
    letter-spacing: 1px;
}

.caption {
    position: absolute;
    bottom: 1.2rem;
    color: white;
    font-size: 12px;
    left: 1rem;
    letter-spacing: .8px;
    font-weight: 100;
    background: #42692c;
    padding: 10px;
    
}

.fw-700 {
    font-weight: 700;
}

.contact_grid_right input[type="text"],
.contact_grid_right input[type="email"],
.contact_grid_right input[type="tel"],
.contact_grid_right textarea {
    outline: none;
    padding: 15px 15px;
    font-size: 14px;
    background: white;
    width: 100%;
    letter-spacing: 1px;
    border: 1px solid #5b5f6b;
    border-radius: 0.25em;
}

.contact_grid_right input[type="text"]:placeholder-shown,
.contact_grid_right input[type="email"]:placeholder-shown,
.contact_grid_right input[type="tel"]:placeholder-shown,
.contact_grid_right textarea:placeholder-shown {
    color: #777;
    background: #282b34ab;

    border: 1px solid #5b5f6b;

}

.contact_grid_right input[type="text"]:focus,
.contact_grid_right input[type="email"]:focus,
.contact_grid_right input[type="tel"]:focus,
.contact_grid_right textarea:focus {
    background: white;
}

.bg-mint {
    background: #96ecaf
}


@media (min-width: 1215px) {
    .team-img-l {
        position:relative;
    }
    .team-img-r {
        position:relative;
    }
    
    .team-desc-l {
        position:relative;
        left: -10px;
    }
    .team-desc-r {
        position:relative;
        left: 10px;
    }
}

.team-desc-l h6, .team-desc-r h6 {
    letter-spacing: 2px;
    font-weight: 100;
}

.team-img-actual {
    width: 100%
}

@media(max-width: 767px) {
    .team-img-actual {
        width: 65%;
        margin: 0 auto;
        display: block;
    }
    .team-desc-l, .team-desc-r {
        margin: 15px;
    }
}

#mobile-nav {
    width: 275px;
    height: 100vh;
    position: fixed;
    top: 0px;
    background: #1d3c6f;
    z-index: 999;
    transition: .5s left;
    text-align: center;
}

@media(max-width: 350px) {

}

#mobile-nav a {
    color: white;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
    border-bottom: 1px solid #517ca5;
    padding: 10px;
    width: 200px;
    display: block;
}

.nav-hide {
    left: -110vw;
}
.nav-visible {
    left: 0px;
}

.ls-none {
    list-style: none;
}

#close-nav {
    position: absolute;
    right: 40px;
    top: 40px;
    font-size: 30px;
    color: white;
}

.mt-30-pc {
    margin-top: 30% !important;
}

.m-0-auto {
    margin: 0 auto;
}

@media (max-width: 650px) {
    .top-bar-w3layouts {
        display: none;
    }
}

#mobile-nav-backdrop {
    width: 100vw;
    height: 100vh;
    display: block;
    position: fixed;
    background: rgba(63, 86, 113, 0.81);
    z-index: 999;
    transition: .5s all;
}

.backdrop-visible {
    opacity: .7;
    visibility: visible;
}
.backdrop-hidden {
    opacity: 0;
    visibility: hidden;
}

.mobile-nav-links {
    letter-spacing: 1.5px;
    line-height: 200%;
}

.f-serif {
    transform: scaleY(.9);
    font-family: intiial;
    letter-spacing: 2.2px;
    font-weight: 900;
}

.mb-none {
    margin-bottom: 0;
}

.pt-12 {
    font-size: 12px;
}

.lh-20 {
    line-height: 20px;
}

#experience {
    margin-top: 40px;
    padding-top: 20px;
}

/**************** Loader */
.loader,
.loader:before,
.loader:after {
  background: rgb(32, 61, 107);
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: rgb(32, 61, 107);
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

/* ////Loader *********/